import * as ScrollMagic2 from 'scrollmagic';

let controller;

if (typeof window !== 'undefined') {
  controller = new ScrollMagic2.Controller();
}

export default class ScrollMagic {
  static get Controller() {
    return controller;
  }
}
